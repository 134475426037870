<template>
  <div>
    <el-card shadow="never" class="card-custom-all">
      <p class="name-head">เพิ่มข้อมูลประกันการส่งออก</p>
      <el-row :gutter="30">
        <el-col :span="24">
          <el-row :gutter="30">
            <el-col :span="24" :md="12">
              <el-form-item label="นำเสนอประกันการส่งออกไปยังระบบ">
                <el-checkbox
                  :disabled="disabledChannel"
                  v-model="formData.sendToCH"
                  >ระบบ Channel Integration</el-checkbox
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row :gutter="30">
            <el-col :span="24" :md="12">
              <el-form-item
                label="Insurance Product ID"
                prop="insuranceProductId"
              >
                <el-select
                  id="formDataInsuranceProductId"
                  v-model="formData.insuranceProductId"
                  filterable
                  clearable
                  @change="handleSelectInsurance"
                  placeholder="เลือก Insurance Product ID"
                >
                  <el-option
                    v-for="item in optionsInsurance"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="ชื่อประกัน" prop="insuranceName">
                <el-input
                  id="formDataInsuranceName"
                  v-model="formData.insuranceName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item
                label="ระยะเวลาอนุมัติวงเงิน"
                prop="datePicker"
                class="is-required"
              >
                <el-date-picker
                  id="formDataDate"
                  class="custom-date-picker"
                  v-model="formData.date"
                  type="daterange"
                  start-placeholder="วันที่เริ่ม"
                  range-separator="ถึง"
                  end-placeholder="วันที่สิ้นสุด"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="รูปภาพ">
                <div class="box-addImg">
                  <el-upload
                    action="#"
                    :auto-upload="false"
                    :on-change="
                      (file, fileList) => uploadDocuments(file, fileList)
                    "
                    :on-remove="handleRemoveDocuments"
                    accept="image/jpeg,image,image/png"
                    list-type="picture"
                    :limit="1"
                    :on-exceed="handleExceed"
                  >
                    <el-button type="primary" class="font-16"
                      >แนบไฟล์</el-button
                    >
                    <p
                      class="text-left mg-0 text-grey"
                      style="margin-top: -8px !important"
                    >
                      Max file size: 25MB
                    </p>
                  </el-upload>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-form-item class="custom-editor" prop="titleOne">
            <div class="box-name-editor">
              <el-input
                id="titleOne"
                placeholder="กรุณากรอกชื่อหัวข้อ"
                v-model="formData.titleOne"
                clearable
              >
              </el-input>
              <el-switch
                id="statusOne"
                class="switch-show-close"
                v-model="formData.statusOne"
                :active-text="formData.statusOne == true ? 'แสดง' : 'ซ่อน'"
              >
              </el-switch>
            </div>
          </el-form-item>
          <QuillTextEditor
            :data="formData"
            :name="`insuranceOverview`"
            :pageName="`insurance`"
          />
          <el-form-item class="custom-editor" prop="titleTwo">
            <div class="box-name-editor">
              <el-input
                id="titleTwo"
                placeholder="กรุณากรอกชื่อหัวข้อ"
                v-model="formData.titleTwo"
                clearable
              >
              </el-input>
              <el-switch
                id="statusTwo"
                class="switch-show-close"
                v-model="formData.statusTwo"
                :active-text="formData.statusTwo == true ? 'แสดง' : 'ซ่อน'"
              >
              </el-switch>
            </div>
          </el-form-item>
          <QuillTextEditor
            :data="formData"
            :name="`insuranceCondition`"
            :pageName="`insurance`"
          />
          <el-form-item class="custom-editor" prop="titleThree">
            <div class="box-name-editor">
              <el-input
                id="titleThree"
                placeholder="กรุณากรอกชื่อหัวข้อ"
                v-model="formData.titleThree"
                clearable
              >
              </el-input>
              <el-switch
                id="statusThree"
                class="switch-show-close"
                v-model="formData.statusThree"
                :active-text="formData.statusThree == true ? 'แสดง' : 'ซ่อน'"
              >
              </el-switch>
            </div>
          </el-form-item>
          <QuillTextEditor
            :data="formData"
            :name="`protectedRisk`"
            :pageName="`insurance`"
          />
          <el-form-item class="custom-editor" prop="titleFour">
            <div class="box-name-editor">
              <el-input
                id="titleFour"
                placeholder="กรุณากรอกชื่อหัวข้อ"
                v-model="formData.titleFour"
                clearable
              >
              </el-input>
              <el-switch
                id="statusFour"
                class="switch-show-close"
                v-model="formData.statusFour"
                :active-text="formData.statusFour == true ? 'แสดง' : 'ซ่อน'"
              >
              </el-switch>
            </div>
          </el-form-item>
          <QuillTextEditor
            :data="formData"
            :name="`converageRate`"
            :pageName="`insurance`"
          />
          <el-form-item class="custom-editor" prop="titleFive">
            <div class="box-name-editor">
              <el-input
                id="titleFive"
                placeholder="กรุณากรอกชื่อหัวข้อ"
                v-model="formData.titleFive"
                clearable
              >
              </el-input>
              <el-switch
                id="statusFive"
                class="switch-show-close"
                v-model="formData.statusFive"
                :active-text="formData.statusFive == true ? 'แสดง' : 'ซ่อน'"
              >
              </el-switch>
            </div>
          </el-form-item>
          <QuillTextEditor
            :data="formData"
            :name="`insuranceCost`"
            :pageName="`insurance`"
          />
          <el-form-item class="custom-editor" prop="titleSix">
            <div class="box-name-editor">
              <el-input
                id="titleSix"
                placeholder="กรุณากรอกชื่อหัวข้อ"
                v-model="formData.titleSix"
                clearable
              >
              </el-input>
              <el-switch
                id="statusSix"
                class="switch-show-close"
                v-model="formData.statusSix"
                :active-text="formData.statusSix == true ? 'แสดง' : 'ซ่อน'"
              >
              </el-switch>
            </div>
          </el-form-item>
          <QuillTextEditor
            :data="formData"
            :name="`insuranceClaimPay`"
            :pageName="`insurance`"
          />
          <el-form-item class="custom-editor" prop="titleSeven">
            <div class="box-name-editor">
              <el-input
                id="titleSeven"
                placeholder="กรุณากรอกชื่อหัวข้อ"
                v-model="formData.titleSeven"
                clearable
              >
              </el-input>
              <el-switch
                id="statusSeven"
                class="switch-show-close"
                v-model="formData.statusSeven"
                :active-text="formData.statusSeven == true ? 'แสดง' : 'ซ่อน'"
              >
              </el-switch>
            </div>
          </el-form-item>
          <QuillTextEditor
            :data="formData"
            :name="`serviceRequestProcess`"
            :pageName="`insurance`"
          />
          <el-form-item class="custom-editor" prop="titleEight">
            <div class="box-name-editor">
              <el-input
                id="titleEight"
                placeholder="กรุณากรอกชื่อหัวข้อ"
                v-model="formData.titleEight"
                clearable
              >
              </el-input>
              <el-switch
                id="statusEight"
                class="switch-show-close"
                v-model="formData.statusEight"
                :active-text="formData.statusEight == true ? 'แสดง' : 'ซ่อน'"
              >
              </el-switch>
            </div>
          </el-form-item>
          <QuillTextEditor
            :data="formData"
            :name="`specialCondition`"
            :pageName="`insurance`"
          />
          <el-form-item class="custom-editor" prop="titleNine">
            <div class="box-name-editor">
              <el-input
                id="titleNine"
                placeholder="กรุณากรอกชื่อหัวข้อ"
                v-model="formData.titleNine"
                clearable
              >
              </el-input>
              <el-switch
                id="statusNine"
                class="switch-show-close"
                v-model="formData.statusNine"
                :active-text="formData.statusNine == true ? 'แสดง' : 'ซ่อน'"
              >
              </el-switch>
            </div>
          </el-form-item>
          <QuillTextEditor
            :data="formData"
            :name="`detailsNine`"
            :pageName="`insurance`"
          />
        </el-col>
      </el-row>
      <div class="is-flex js-end">
        <router-link :to="`/manage/export-insurance`">
          <el-button class="font-16 btn-cancle"> ยกเลิก</el-button>
        </router-link>
        <el-button
          type="primary"
          class="font-16"
          @click="$emit('insuranceAdd')"
          :loading="loadingSave"
          >บันทึก</el-button
        >
      </div>
    </el-card>
  </div>
</template>
<script>
//quill editor
import QuillTextEditor from "@/components/editor/QuillTextEditor";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  components: { QuillTextEditor },
  props: ["formData", "loadingSave", "optionsInsurance"],
  mounted() {
    // console.log('token', this.token);
    this.checkStatusChannel();
  },
  data() {
    return {
      value1: false,
      disabledChannel: false,
    };
  },
  methods: {
    uploadDocuments(file, fileList) {
      let typeThis = file.raw.name.split(".");
      if (
        typeThis[1] == "jpeg" ||
        typeThis[1] == "png" ||
        typeThis[1] == "jpg"
      ) {
        if (file.size < 25000000) {
          let obj = {
            name: file.name,
            file: file,
          };
          this.formData.image.push(obj);
        } else {
          let index = fileList.findIndex((a) => a.uid == file.uid);
          fileList.splice(index, 1);
          this.$message({
            message: "กรุณาเลือกไฟล์ขนาดไม่เกิน 25mb",
            type: "error",
            duration: 4000,
          });
        }
      } else {
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
        this.$message({
          message: "กรุณาเลือกไฟล์นามสกุลเป็น JPEG หรือ PNG เท่านั้น",
          type: "error",
          duration: 4000,
        });
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(`คุณสามารถแนบไฟล์ได้สูงสุด 1 ไฟล์`);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleRemoveDocuments(file) {
      let index = this.formData.image.findIndex((f) => f.file.uid == file.uid);
      this.formData.image.splice(index, 1);
    },
    handleSelectInsurance() {
      //
    },
    checkStatusChannel() {
      var obj = { productId: 0 };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post("/ch/insurance", obj)
        .then((res) => {
          // console.log('Response', res.data);
          if (res.data.success == false && res.data.status == 200) {
            if (res.data.obj.status == true) {
              this.disabledChannel = true;
              this.formData.sendToCH = false;
            }
          } else if (res.data.success == true && res.data.status == 200) {
            this.disabledChannel = false;
          }
        })
        .catch((err) => {
          console.log("Error Check Status Channel", err.response.data);
        });
    },
  },
  computed: {
    ...mapState({
      token: (state) => state._token,
    }),
  },
};
</script>
