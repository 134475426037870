<template>
  <div>
    <el-card shadow="never" class="card-custom-all">
      <p class="name-head">เพิ่มเกณฑ์ในการพิจารณา</p>

      <el-row :gutter="30">
        <el-col :span="24" :md="12" class="box-set-tooltip">
          <el-form-item
            label="ขนาดธุรกิจ"
            class="mg-0 is-required"
            prop="insuranceSize"
          >
            <el-checkbox v-model="formData.insuranceSizeS" @change="sendData"
              >Size S</el-checkbox
            >
            <el-checkbox v-model="formData.insuranceSizeM" @change="sendData"
              >Size M</el-checkbox
            >
            <el-checkbox v-model="formData.insuranceSizeL" @change="sendData"
              >Size L</el-checkbox
            >
          </el-form-item>

          <el-tooltip
            class="item font-14 custom-tooltip"
            effect="dark"
            placement="bottom-start"
          >
            <div slot="content">
              <span>
                ลักษณะธุรกิจ <br />
                1. ธุรกิจการผลิต คำนวนขนาดธุรกิจจาก สสว. ใหม่ ใช้เกณฑ์ <br />
                - SIZE S 0 - 100 ล้านบาท <br />
                - SIZE M มากกว่า 100 - 500 ล้านบาท <br />
                - SIZE L มากกว่า 500 ล้านบาทขึ้นไป <br />
                2. ธุรกิจบริการ และ ธุรกิจแบบซื้อมาขายไป คำนวนขนาดธุรกิจจาก สสว.
                ใหม่ ใช้เกณฑ์ <br />
                - SIZE S 0 - 50 ล้านบาท <br />
                - SIZE M มากกว่า 50 - 300 ล้านบาท <br />
                - SIZE L มากกว่า 300 ล้านบาทขึ้นไป
              </span>
            </div>
            <i class="fas fa-question-circle"></i>
          </el-tooltip>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="วงเงินค้าขายต่อ Shipment">
            <el-select
              clearable
              id="formDataShipment"
              v-model="formData.shipment"
              placeholder="เลือกวงเงินค้าขายต่อ Shipment"
              @change="sendData"
              multiple
              default-first-option
              popper-class="custom-select"
            >
              <el-option
                v-for="item in optionsShipment"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <div class="is-flex ai-center">
                  <div class="circle-select"></div>
                  {{ item.label }}
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="มูลค่าการส่งออก">
            <el-select
              clearable
              id="formDataExportValue"
              v-model="formData.exportValue"
              placeholder="มูลค่าการส่งออก"
              @change="sendData"
              multiple
              default-first-option
              popper-class="custom-select"
            >
              <el-option
                v-for="item in optionsExportValue"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <div class="is-flex ai-center">
                  <div class="circle-select"></div>
                  {{ item.label }}
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="เทอมการชำระเงิน">
            <el-select
              clearable
              id="formDataPaymentTerm"
              v-model="formData.term"
              placeholder="เทอมการชำระเงิน"
              @change="sendData"
              multiple
              default-first-option
              popper-class="custom-select"
            >
              <el-option
                v-for="item in optionsPaymentTerm"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <div class="is-flex ai-center">
                  <div class="circle-select"></div>
                  {{ item.label }}
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="โครงการจากระบบ TERAK">
            <el-select
              clearable
              filterable
              id="formDataProjectTERAK"
              v-model="formData.projectTERAK"
              placeholder="เลือกโครงการจากระบบ TERAK"
              @change="selectTERAK"
              multiple
              default-first-option
              popper-class="custom-select"
            >
              <el-option
                v-for="item in optionsRef"
                :key="item.id"
                :label="item.projectName"
                :value="item.id"
              >
                <div class="is-flex ai-center">
                  <div class="circle-select"></div>
                  {{ item.projectName }}
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import { TERAK } from "@/service/axios";
import { mapState } from "vuex";
import crypto from "crypto";
const algorithm = "aes-256-ctr";
const secretKey = "vwPH9sdmpnVjrTIQCc7qSXs11lWhzFrT";
const iv = crypto.randomBytes(16);
import moment from "moment";
export default {
  props: ["formData"],
  mounted() {
    this.getProjectByTerak("CFOE2021");
  },
  data() {
    return {
      constSelect2: [],
      optionsRef: [],
      optionsShipment: [
        {
          value: 1,
          label: "น้อยกว่า 0.5 ล้านบาท",
        },
        {
          value: 2,
          label: "0.5 - 1 ล้านบาท",
        },
        {
          value: 3,
          label: "1 ล้านบาทขึ้นไป",
        },
      ],
      optionsExportValue: [
        { value: 1, label: "ไม่เกิน 100 ล้านบาท" },
        { value: 2, label: "101-200 ล้านบาท" },
        { value: 3, label: "201-300 ล้านบาท" },
        { value: 4, label: "301-400 ล้านบาท" },
        { value: 5, label: "401-500 ล้านบาท" },
        { value: 6, label: "มากกว่า 500 ล้านบาท" },
      ],
      optionsPaymentTerm: [
        {
          value: 90,
          label: "ไม่เกิน 90 วัน",
        },
        {
          value: 120,
          label: "ไม่เกิน 120 วัน",
        },
        {
          value: 180,
          label: "ไม่เกิน 180 วัน",
        },
      ],
    };
  },
  methods: {
    getProjectByTerak(text) {
      try {
        var ivstring = iv.toString("hex").slice(0, 16);
        const cipher = crypto.createCipheriv(algorithm, secretKey, ivstring);
        const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

        let obj = {
          iv: ivstring.toString("hex"),
          content: encrypted.toString("hex"),
        };
        TERAK.defaults.headers.common.Authorization = `Bearer ${obj.iv +
          ":" +
          obj.content}`;
        TERAK.get(`cfoe/refs`)
          .then((res) => {
            if (res.data.success) {
              res.data.obj.forEach((item) => {
                let expire = false;
                // let dateNow = moment(new Date()).format("yyyy-MM-DD");
                // let dateEnd = moment(item.endDate).format("yyyy-MM-DD");
                // if (dateNow > dateEnd) {
                //   expire = true;
                // }
                !expire
                  ? this.optionsRef.push({
                      ...item,
                      expire: expire,
                    })
                  : "";
              });
              this.optionsRef.unshift({
                id: Number("999"),
                expire: false,
                projectName: "ทั้งหมด",
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log("Error: encrypt", e);
        return false;
      }
    },
    selectTERAK(val) {
      if (val[val.length - 1] == 999) {
        this.formData.projectTERAK = [];
        this.optionsRef.forEach((gAll) => {
          this.formData.projectTERAK.push(gAll.id);
        });
        this.constSelect2 = JSON.parse(
          JSON.stringify(this.formData.projectTERAK)
        );
      } else {
        if (val[0] === 999 && val.length < this.optionsRef.length) {
          this.formData.projectTERAK.splice(0, 1);
          this.constSelect2 = JSON.parse(
            JSON.stringify(this.formData.projectTERAK)
          );
        } else if (this.constSelect2[0] === 999 && val[0] !== 999) {
          this.formData.projectTERAK = [];
          this.constSelect2 = JSON.parse(
            JSON.stringify(this.formData.projectTERAK)
          );
        } else {
          let fn = this.constSelect2.findIndex((a) => a === 999);
          if (val.length == this.optionsRef.length - 1 && fn === -1) {
            this.formData.projectTERAK = [];
            this.optionsRef.forEach((gAll) => {
              this.formData.projectTERAK.push(gAll.id);
            });
            this.constSelect2 = JSON.parse(
              JSON.stringify(this.formData.projectTERAK)
            );
          }
        }
      }
      this.sendData();
    },
    sendData() {
      // if (this.formData.shipment == "") {
      //   this.formData.shipmentMin = 0;
      //   this.formData.shipmentMax = 0;
      // } else if (this.formData.shipment == "<500000") {
      //   this.formData.shipmentMin = 0;
      //   this.formData.shipmentMax = 500000;
      // } else if (this.formData.shipment == ">500000") {
      //   this.formData.shipmentMin = 500000;
      //   this.formData.shipmentMax = 0;
      // }
      // if (this.formData.exportValue == "") {
      //   this.formData.exportValueMin = 0;
      //   this.formData.exportValueMax = 0;
      // } else if (this.formData.exportValue == 1) {
      //   this.formData.exportValueMin = 0;
      //   this.formData.exportValueMax = 200000000;
      // } else if (this.formData.exportValue == 2) {
      //   this.formData.exportValueMin = 200000000;
      //   this.formData.exportValueMax = 500000000;
      // }

      this.$emit("stepNow", this.formData);
    },
  },
};
</script>
