<template>
  <DefaultTemplate>
    <div class="box-name-page">
      <div>
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="set-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/manage/export-insurance' }"
            >จัดการข้อมูลประกัน</el-breadcrumb-item
          >
          <el-breadcrumb-item>เพิ่มข้อมูลประกัน</el-breadcrumb-item>
        </el-breadcrumb>
        <p class="title mg-t-5">เพิ่มข้อมูลประกัน</p>
      </div>
    </div>

    <el-form
      label-position="top"
      :model="form"
      class="set-form"
      :rules="rules"
      ref="form"
    >
      <Step1 @stepNow="formDataOut" :formData="form" />
      <Step2
        v-if="stepTwo"
        @insuranceAdd="insuranceAdd"
        :formData="form"
        :optionsInsurance="optionsInsurance"
        :loadingSave="loadingSave"
      />
    </el-form>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import Step1 from "@/components/export/Step1";
import Step2 from "@/components/export/Step2";
import { HTTP } from "@/service/axios";
import { TERAK } from "@/service/axios";
import jwt from "jsonwebtoken";
import crypto from "crypto";
const algorithm = "aes-256-ctr";
const secretKey = "vwPH9sdmpnVjrTIQCc7qSXs11lWhzFrT";
const iv = crypto.randomBytes(16);

export default {
  components: {
    DefaultTemplate,
    Step1,
    Step2,
  },

  data() {
    var validateSize = (rule, value, callback) => {
      if (
        !this.form.insuranceSizeS &&
        !this.form.insuranceSizeM &&
        !this.form.insuranceSizeL
      ) {
        callback(new Error("กรุณาเลือกขนาดธุรกิจ"));
      } else {
        callback();
      }
    };
    var validateDatePicker = (rule, value, callback) => {
      if (this.form.date.length == 0) {
        callback(new Error("กรุณาเลือกวันที่"));
      } else {
        callback();
      }
    };
    var validateExportValue = (rule, value, callback) => {
      if (
        this.form.insuranceSizeS == true &&
        this.form.shipment == "<500000" &&
        this.form.exportValue == ""
      ) {
        callback();
      }
      if (this.form.exportValue == "") {
        callback(new Error("กรุณาเลือกมูลค่าการส่งออก"));
      } else {
        callback();
      }
    };

    var validtitleOne = (rule, value, callback) => {
      if (this.form.statusOne && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleTwo = (rule, value, callback) => {
      if (this.form.statusTwo && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleThree = (rule, value, callback) => {
      if (this.form.statusThree && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleFour = (rule, value, callback) => {
      if (this.form.statusFour && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleFive = (rule, value, callback) => {
      if (this.form.statusFive && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleSix = (rule, value, callback) => {
      if (this.form.statusSix && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleSeven = (rule, value, callback) => {
      if (this.form.statusSeven && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleEight = (rule, value, callback) => {
      if (this.form.statusEight && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleNine = (rule, value, callback) => {
      if (this.form.statusNine && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    return {
      loadingSave: false,
      form: {
        sendToCH:false,
        projectTERAK: [],
        insuranceProductId: "",
        insuranceSizeS: false,
        insuranceSizeM: false,
        insuranceSizeL: false,
        shipment: [],
        exportValue: [],
        term: [],
        insuranceName: "",
        date: [],
        insuranceStart: "",
        insuranceEnd: "",
        insuranceOverview: "",
        insuranceCondition: "",
        protectedRisk: "",
        converageRate: "",
        insuranceCost: "",
        insuranceClaimPay: "",
        serviceRequestProcess: "",
        specialCondition: "",
        image: [],
        titleOne: "ลักษณะผลิตภัณฑ์/บริการ",// เปลี่ยนจาก ภาพรวม
        titleTwo: "เงื่อนไขการรับประกัน",
        titleThree: "ความเสี่ยงที่คุ้มครอง",
        titleFour: "ความคุ้มครอง",// เปลี่ยนจาก อัตราความคุ้มครอง
        titleFive: "วงเงินรับประกันสูงสุด/ค่าใช้จ่าย",// เปลี่ยนจาก ค่าใช้จ่าย
        titleSix: "การจ่ายค่าสินไหมทดแทน",// เปลี่ยนจาก ระยะเวลาในการจ่ายค่าสินไหมทดแทน
        titleSeven: "ขั้นตอนการขอใช้บริการ",
        titleEight: "เงื่อนไขพิเศษ",
        titleNine: "เงื่อนไขพิเศษ",
        statusOne: true,
        statusTwo: true,
        statusThree: true,
        statusFour: true,
        statusFive: true,
        statusSix: true,
        statusSeven: true,
        statusEight: true,
        statusNine: false,

        detailsNine: "",
      },
      rules: {
        titleOne: [{ validator: validtitleOne, trigger: ["blur", "change"] }],
        titleTwo: [{ validator: validtitleTwo, trigger: ["blur", "change"] }],
        titleThree: [
          { validator: validtitleThree, trigger: ["blur", "change"] },
        ],
        titleFour: [{ validator: validtitleFour, trigger: ["blur", "change"] }],
        titleFive: [{ validator: validtitleFive, trigger: ["blur", "change"] }],
        titleSix: [{ validator: validtitleSix, trigger: ["blur", "change"] }],
        titleSeven: [
          { validator: validtitleSeven, trigger: ["blur", "change"] },
        ],
        titleEight: [
          { validator: validtitleEight, trigger: ["blur", "change"] },
        ],
        titleNine: [{ validator: validtitleNine, trigger: ["blur", "change"] }],
        projectTERAK: [
          {
            required: true,
            message: "กรุณาเลือกโครงการจากระบบ TERAK",
            trigger: "change",
          },
        ],
        insuranceProductId: [
          {
            required: true,
            message: "กรุณากรอก Insurance Product ID",
            trigger: ["blur", "change"],
          },
        ],
        shipment: [
          {
            required: true,
            message: "กรุณาเลือกวงเงินค้าขายต่อ Shipment",
            trigger: ["blur", "change"],
          },
        ],
        paymentTerm: [
          {
            required: true,
            message: "กรุณาเลือกเทอมการชำระเงิน",
            trigger: ["blur", "change"],
          },
        ],
        insuranceName: [
          { required: true, message: "กรุณากรอกชื่อประกัน", trigger: "blur" },
        ],
        insuranceSize: [{ validator: validateSize, trigger: "change" }],
        exportValue: [
          { validator: validateExportValue, trigger: ["blur", "change"] },
        ],
        datePicker: [{ validator: validateDatePicker, trigger: "change" }],
      },
      optionsInsurance: [],
      stepTwo: false,
    };
  },

  mounted() {
    this.getProductId();
  },
  methods: {
    getProductId() {
      HTTP.get(`crm/insurance/master`)
        .then((res) => {
          if (res.data.success) {
            this.optionsInsurance = res.data.obj;
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    formDataOut(data) {
      if (data.insuranceSizeS || data.insuranceSizeM || data.insuranceSizeL) {
        this.form = data;
        this.stepTwo = true;
      } else {
        this.stepTwo = false;
      }
    },
    async insuranceAdd() {
      this.$refs["form"].validate().then(async (valid) => {
        if (valid) {
          this.loadingSave = true;
          let resImg = "";
          if (this.form.image.length > 0) {
            var formDataTERAK = new FormData();
            formDataTERAK.append("file", this.form.image[0].file.raw);
            var token = jwt.sign(
              { system: "CFOE2021" },
              "Y2weQEzYjje3LRDiwAUiX1U2r",
              { expiresIn: "5m" }
            );

            TERAK.defaults.headers.common.Authorization = token;
            const res = await TERAK.post(`cfoe/file`, formDataTERAK);
            if (res.data.success) {
              resImg = res.data.filename;
            }
          }
          let projectTERAK = this.form.projectTERAK.findIndex((a) => a === 999);
          if (projectTERAK > -1) {
            this.form.projectTERAK.splice(projectTERAK, 1);
          }
          let forShipment = [];
          let forExportValue = [];
          let forTerm = [];
          let forProjectTERAK = [];

          this.form.shipment.forEach((row) => {
            forShipment.push(row.toString());
          });
          
          this.form.exportValue.forEach((row) => {
            forExportValue.push(row.toString());
          });
          
          this.form.term.forEach((row) => {
            forTerm.push(row.toString());
          });
          
          this.form.projectTERAK.forEach((row) => {
            forProjectTERAK.push(row.toString());
          });

          let obj = {
            sendToCH: this.form.sendToCH,
            insuranceSizeS: this.form.insuranceSizeS,
            insuranceSizeM: this.form.insuranceSizeM,
            insuranceSizeL: this.form.insuranceSizeL,
            insuranceName: this.form.insuranceName,
            shipment: forShipment,
            exportValue: forExportValue,
            term: forTerm,
            // shipmentMin: this.form.shipmentMin,
            // shipmentMax: this.form.shipmentMax,
            insuranceStart: this.form.date[0],
            insuranceEnd: this.form.date[1],
            insuranceOverview: this.form.insuranceOverview,
            insuranceCondition: this.form.insuranceCondition,
            protectedRisk: this.form.protectedRisk,
            converageRate: this.form.converageRate,
            insuranceCost: this.form.insuranceCost,
            insuranceClaimPay: this.form.insuranceClaimPay,
            serviceRequestProcess: this.form.serviceRequestProcess,
            specialCondition: this.form.specialCondition,
            projectTERAK: forProjectTERAK,
            insuranceProductId: this.form.insuranceProductId,
            image: resImg,
            titleOne: this.form.titleOne,
            titleTwo: this.form.titleTwo,
            titleThree: this.form.titleThree,
            titleFour: this.form.titleFour,
            titleFive: this.form.titleFive,
            titleSix: this.form.titleSix,
            titleSeven: this.form.titleSeven,
            titleEight: this.form.titleEight,
            titleNine: this.form.titleNine,
            statusOne: this.form.statusOne,
            statusTwo: this.form.statusTwo,
            statusThree: this.form.statusThree,
            statusFour: this.form.statusFour,
            statusFive: this.form.statusFive,
            statusSix: this.form.statusSix,
            statusSeven: this.form.statusSeven,
            statusEight: this.form.statusEight,
            statusNine: this.form.statusNine,

            detailsNine: this.form.detailsNine,
          };

          HTTP.post(`insurance/create`, obj)
            .then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "เพิ่มข้อมูลประกันสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.$router.push(`/manage/export-insurance`);
              } else {
                if (res.data.status == 403) {
                  this.$store.commit("SET_PAGE_ACTIVE", null);
                  this.$router.push("/not-allowed");
                } else if (res.data.status == 204) {
                  this.loadingSave = false;
                  this.$message({
                    message: "ชื่อข้อมูลประกันซ้ำ",
                    type: "error",
                    duration: 4000,
                  });
                }
              }
            })
            .catch((e) => {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else {
                this.alertCatchError(e);
              }
            });
        }
      });
    },
  },
};
</script>
